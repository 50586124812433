<template>
    <div>
        <transition name="vload">
            <div v-show="isLoading" class="Loader">
                <div class="sk-cube-grid">
                    <div class="sk-cube sk-cube1"></div>
                    <div class="sk-cube sk-cube2"></div>
                    <div class="sk-cube sk-cube3"></div>
                    <div class="sk-cube sk-cube4"></div>
                    <div class="sk-cube sk-cube5"></div>
                    <div class="sk-cube sk-cube6"></div>
                    <div class="sk-cube sk-cube7"></div>
                    <div class="sk-cube sk-cube8"></div>
                    <div class="sk-cube sk-cube9"></div>
                </div>
                <!--<i class="fa fa-spinner fa-spin fa-5x fa-fw" style="color:green"></i>-->
            </div>
        </transition>
        <div id="main-wrapper">
            <Header></Header>
						<div class="container jobinfo-detail">
            <div class="header-margin"></div>
            <div class="modal-header my-4">
                <h4 style="font-size: 18px;">求人詳細</h4>
            </div>
            <div v-if="jobInfoData">
                <ul class="no-ul-list filter-list">
                    <li style="display: inline-flex;"><p class="job-form-label-position">{{getJobCategoryText(jobInfoData.jobCategory)}}</p></li>
                    <li class="job-form-title">{{jobInfoData.title}}</li>
                    <li class="job-form-company">企業名：{{jobInfoData.company.name}}</li>
                    <li class="job-form-digest"><p style="font-weight: 600; margin-bottom: -20px">業務内容</p><br>{{jobInfoData.jobDetail}}</li>
                    <li class="job-list-frame"><p class="job-form-label">職種</p><p class="job-form-main-listtext">{{getJobCategoryText(jobInfoData.jobCategory)}}</p></li>
                    <li v-if="jobInfoData.jobFeatures.length > 0" class="job-list-frame"><p class="job-form-label">求人の特徴</p><p class="job-form-main-listtext">{{jobInfoData.jobFeatures.map((jobFeature) => getJobFeatureText(jobFeature)).join(", ")}}</p></li>
                    <li class="job-list-frame"><p class="job-form-label">必須スキル</p><p class="job-form-main-listtext">{{jobInfoData.requiredSkill}}</p></li>
                    <li v-if="jobInfoData.recommendedSkill" class="job-list-frame"><p class="job-form-label">推奨スキル</p><p class="job-form-main-listtext">{{jobInfoData.recommendedSkill}}</p></li>
                    <li class="job-list-frame"><p class="job-form-label">勤務地</p><p class="job-form-main-listtext">{{jobInfoData.workLocation}}</p></li>
                    <li class="job-list-frame"><p class="job-form-label">最寄駅</p><p class="job-form-main-listtext">{{jobInfoData.nearestStation}}</p></li>
                    <li class="job-list-frame"><p class="job-form-label">想定給与</p><p class="job-form-main-listtext">{{jobInfoData.estimatedSalary}}</p></li>
                    <li v-if="jobInfoData.bonus" class="job-list-frame"><p class="job-form-label">賞与</p><p class="job-form-main-listtext">{{jobInfoData.bonus}}</p></li>
                    <li v-if="jobInfoData.evaluationSystem" class="job-list-frame"><p class="job-form-label">評価・昇給</p><p class="job-form-main-listtext">{{jobInfoData.evaluationSystem}}</p></li>
                    <li class="job-list-frame"><p class="job-form-label">週休制</p><p class="job-form-main-listtext">{{getWeeklyHolidaySystemText(jobInfoData.weeklyHolidaySystem)}}</p></li>
                    <li class="job-list-frame"><p class="job-form-label">雇用形態</p><p class="job-form-main-listtext">{{getEmploymentStatusText(jobInfoData.employmentStatus)}}</p></li>
                    <li v-if="jobInfoData.vacations.length > 0" class="job-list-frame"><p class="job-form-label">休暇</p><p class="job-form-main-listtext">{{jobInfoData.vacations.map((vacation) => getVacationText(vacation)).join(", ")}}</p></li>
                    <li v-if="jobInfoData.welfareSystems" class="job-list-frame"><p class="job-form-label">福利厚生</p><p class="job-form-main-listtext">{{jobInfoData.welfareSystems}}</p></li>
                    <li class="job-list-frame"><p class="job-form-label">勤務時間</p><p class="job-form-main-listtext">{{jobInfoData.workingStartTime}} 〜 {{jobInfoData.workingEndTime}}</p></li>
                    <li class="job-list-frame"><p class="job-form-label">業界歴</p><p class="job-form-main-listtext">{{jobInfoData.industryHistory}}年</p></li>
                    <li v-if="jobInfoData.desiredCandidateProfile" class="job-list-frame"><p class="job-form-label">求める人物像</p><p class="job-form-main-listtext">{{jobInfoData.desiredCandidateProfile}}</p></li>
                    <li v-if="jobInfoData.remarks" class="job-list-frame"><p class="job-form-label">備考</p><p class="job-form-main-listtext">{{jobInfoData.remarks}}</p></li>
                    <template v-if="isSalesType(jobInfoData.jobCategory) && jobInfoData.salesJobInfo">
                        <li v-if="jobInfoData.salesJobInfo.salesStyles.length > 0" class="job-list-frame"><p class="job-form-label">営業スタイル</p><p class="job-form-main-listtext">{{jobInfoData.salesJobInfo.salesStyles.map((salesStyle) => getSalesStyleText(salesStyle)).join(", ")}}</p></li>
                        <li class="job-list-frame"><p class="job-form-label">案件 or 人材</p><p class="job-form-main-listtext">{{getHandledSalesText(jobInfoData.salesJobInfo.handledSales)}}</p></li>
                        <li class="job-list-frame"><p class="job-form-label">役職</p><p class="job-form-main-listtext">{{getSalesJobPositionText(jobInfoData.salesJobInfo.salesJobPosition)}}</p></li>
                        <li v-if="jobInfoData.salesJobInfo.strengthIndustries.length > 0" class="job-list-frame"><p class="job-form-label">得意業界</p><p class="job-form-main-listtext">{{jobInfoData.salesJobInfo.strengthIndustries.map((strengthIndustry) => getStrengthIndustryText(strengthIndustry)).join(", ")}}</p></li>
                    </template>
                    <template v-else-if="jobInfoData.engineerJobInfo">
                        <li class="job-list-frame"><p class="job-form-label">技術領域</p><p class="job-form-main-listtext">{{getTechnicalFieldText(jobInfoData.engineerJobInfo.technicalField)}}</p></li>
                        <li class="job-list-frame"><p class="job-form-label">役職</p><p class="job-form-main-listtext">{{getSalesJobPositionText(jobInfoData.engineerJobInfo.engineerJobPosition)}}</p></li>
                    </template>
                </ul>
                <a class="btn-md full-width pop-login mypage-menu weakbtn orange-btn" style="color: #ffffff; width: 100%" @click="onClickJobContact">応募フォームへ</a>
            </div>
						</div>
            　
            <!-- ========================== Blog Detail Elements ============================= -->
            <Footer></Footer>
        </div>
        <!-- ============================================================== -->
        <!-- End Wrapper -->
        <!-- ============================================================== -->
    </div>
</template>


<script>

    import Header from "@/views/components/Header";
    import Footer from "@/views/components/Footer";
    import MixIn from "@/common/mixin";
    import ApiMixIn from "@/common/api_mixin";
    import {
        getEmploymentStatusText,
        getEngineerJobPositionText,
        getHandledSalesText,
        getJobCategoryText,
        getJobFeatureText,
        getSalesJobPositionText,
        getSalesStyleText,
        getStrengthIndustryText,
        getTechnicalFieldText,
        getVacationText,
        getWeeklyHolidaySystemText,
        JobCategory
    } from "@/common/enum";

    export default {
        name: "JobInfoDetail",
        components: {Footer, Header},
        mixins: [MixIn, ApiMixIn],
        data: function() {
            return {
                isLoading: true,
                jobInfoData: null
            }
        },
        created: function () {
            this.getJobInfo(this.$route.query.id);
        },
        mounted: function() {
            window.scrollTo(0, 0);
        },
        methods: {
            async getJobInfo(id) {
                const response = await this.getApi("jobInfo/get", { id: id });
                if (response && response.data && response.data.jobInfo) {
                    this.jobInfoData = response.data.jobInfo;
                } else {
                    this.pushError("求人情報の取得に失敗しました。");
                }
            },
            getJobCategoryText (jobCategoryType) {
                return getJobCategoryText(jobCategoryType);
            },
            getJobFeatureText (jobFeatureType) {
                return getJobFeatureText(jobFeatureType);
            },
            getWeeklyHolidaySystemText: function (weeklyHolidaySystemType) {
                return getWeeklyHolidaySystemText(weeklyHolidaySystemType);
            },
            getEmploymentStatusText: function (employmentStatusType) {
                return getEmploymentStatusText(employmentStatusType);
            },
            getVacationText: function (vacationType) {
                return getVacationText(vacationType);
            },
            getSalesStyleText: function (salesStyleType) {
                return getSalesStyleText(salesStyleType);
            },
            getHandledSalesText: function (handledSalesType) {
                return getHandledSalesText(handledSalesType);
            },
            getSalesJobPositionText: function (salesJobPositionType) {
                return getSalesJobPositionText(salesJobPositionType);
            },
            getStrengthIndustryText: function (vacationType) {
                return getStrengthIndustryText(vacationType);
            },
            getTechnicalFieldText: function (technicalFieldType) {
                return getTechnicalFieldText(technicalFieldType);
            },
            getEngineerJobPositionText: function (engineerJobPositionType) {
                return getEngineerJobPositionText(engineerJobPositionType);
            },
            isSalesType(jobCategory) {
                return jobCategory === JobCategory.Sales;
            },
            onClickJobContact() {
                // eslint-disable-next-line no-undef
                $('#job-info-detail').modal("hide");
                this.$router.push({ name: 'JobContactForm', query: { id: this.jobInfoData.id } });
            }
        }
    }
</script>

<style scoped>

</style>
